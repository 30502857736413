function getCardToken(cardData, callback) {
    var options = {
        onSuccess: function (response) {
            // Success to get card token_id, implement as you wish here
            console.log('Success to get card token_id, response:', response);
            var token_id = response.token_id;
  
            console.log('This is the card token_id:', token_id);
            // Implement sending the token_id to backend to proceed to next step
            callback(response);
        },
        onFailure: function (response) {
            // Fail to get card token_id, implement as you wish here
            console.log('Fail to get card token_id, response:', response);
            callback(response);
            // you may want to implement displaying failure message to customer.
            // Also record the error message to your log, so you can review
            // what causing failure on this transaction.
        }
    };
    // trigger `getCardToken` function
    MidtransNew3ds.getCardToken(cardData, options);
  }
  
  
  function reqOpen3DS(redirect_url, callback) {
    // const popupModal = (function () {
    //     var modal = null;
    //     return {
    //         openPopup(url) {
    //             modal = Swal.fire({
    //                 html: '<iframe frameborder="0" style="height:90vh; width:100%;" src="' + url + '"></iframe>',
    //                 showCloseButton: true,
    //                 showConfirmButton: false
    //             });
    //         },
    //         closePopup() {
    //             Swal.close()
    //         }
    //     }
    // }());
    // callback functions
    var options = {
        performAuthentication: function (redirect_url) {
            // Implement how you will open iframe to display 3ds authentication redirect_url to customer
           // popupModal.openPopup(redirect_url);
        },
        onSuccess: function (response) {
            // 3ds authentication success, implement payment success scenario
            console.log('response SUKSES:', response);
            // popupModal.closePopup();
            callback(response);
        },
        onFailure: function (response) {
            // 3ds authentication failure, implement payment failure scenario
            console.log('response FAILURE:', response);
            // popupModal.closePopup();
            callback(response);
        },
        onPending: function (response) {
            // transaction is pending, transaction result will be notified later via 
            // HTTP POST notification, implement as you wish here
            console.log('response PENDING:', response);
            // popupModal.closePopup();
            callback(response);
        }
    };
  
    // trigger `authenticate` function
    MidtransNew3ds.authenticate(redirect_url, options);
    
  }


