function callSnap(token, callback) {
  snap.pay(token, {
    onSuccess: function (result) {
      console.log('success');
      console.log(result);
      callback(result);
    },
    onPending: function (result) {
      console.log('pending');
      console.log(result);
      callback(result);
    },
    onError: function (result) {
      console.log('error');
      console.log(result);
      callback(result);
    },
    onClose: function () {
      console.log('customer closed the popup without finishing the payment');
      callback(null);
    },
    
  });
}
